<template>
    <div v-if="this.form.applyId" class="container">
        <step-bar :active="3" @clickStep="clickStep"></step-bar>
        <!-- 验收申请文件 -->
        <ex-card headerName="验收申请文件">
            <div class="commonNotice">
                <div class="content">
                    <div class="uploader" v-for="(item, index) in this.completionApplyFiles" :key="index">
                        <ex-uploader v-model="item.files" :disabled="true"></ex-uploader>
                        <br />
                        <span>{{ item.remark }}</span>
                    </div>
                </div>
            </div>
        </ex-card>

        <!-- 现场作业表 -->
        <ex-card headerName="现场作业表">
            <div class="commonNotice">
                <div class="ulbox">
                    <div>
                        <p>《客户受电工程竣工检验现场作业表》电子文件模板下载</p>
                        <el-button @click="downloadTemplateHandle" type="primary" style="margin-top: 10px"
                            >下载模板
                        </el-button>
                    </div>
                    <div class="uploader">
                        <ex-uploader v-model="completionCheckSceneFiles"></ex-uploader>
                    </div>
                </div>
            </div>
        </ex-card>

        <el-form ref="form" :model="form" :rules="rules">
            <!--客户受电工程竣工检验意见书-->
            <el-card class="card-pub-notice card-ext-center-title">
                <div slot="header" class="notice_title">
                    <span>{{ '客户受电工程竣工检验意见书' }}</span>
                </div>
                <div class="commonNotice">
                    <!--基本信息-->
                    <ex-card headerName="基本信息">
                        <div class="commonNotice">
                            <div class="ulbox">
                                <div>
                                    客户编号：
                                    <span>{{ businessInfo.customerNumber }}</span>
                                </div>
                                <div>
                                    工单号：
                                    <span>{{ businessInfo.businessNumber }}</span>
                                </div>
                                <div>
                                    用电地址：
                                    <span>{{ businessInfo.useAddr }}</span>
                                </div>
                                <div>
                                    报装容量：
                                    <span>{{ businessInfo.totalCapacity }}</span>
                                </div>
                                <div>
                                    客户联系人：
                                    <span>{{ businessInfo.useRelName }}</span>
                                </div>
                                <div>
                                    联系电话：
                                    <span>{{ businessInfo.useRelTel }}</span>
                                </div>
                            </div>
                        </div>
                    </ex-card>
                    <!--客户承诺-->
                    <ex-card headerName="客户承诺">
                        <div class="commonNotice">
                            <div class="ulbox">
                                <div><span style="color: #F56C6C;margin-right: 5px;">*</span>本户受电工程委托有承装（修）电力设施许可证单位安装，</div>
                                <div>
                                    <el-form-item prop="constructionItem">
                                        <el-checkbox-group v-model="form.constructionItem">
                                            <el-checkbox label="1">线路</el-checkbox>
                                            <el-checkbox label="2">变配电工程</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </div>
                                <div>
                                    现已施工完毕，经过自检，工程质量符合国家及电力行业验收标准、技术规范的要求，现向供电部门报请竣工检验。
                                </div>
                                <!--<div style="margin-top: 20px;"><span style="color: #F56C6C;margin-right: 5px;">*</span>客户签名：<el-button @click="openSignDialogHandler(3)">点击进入电子签章</el-button></div>
                                <div>
                                    <img
                                        v-if="customerSignature.file"
                                        :src="`${$client.serverUrl}files/${customerSignature.file.id}?token=${$store.state.token}`"
                                        alt
                                        class="sign-img"
                                    />

                                </div>-->
                            </div>
                        </div>
                    </ex-card>

                    <!--竣工检验项目是否符合标准（默认都为选中“是”）-->
                    <ex-card headerName="竣工检验项目是否符合标准">
                        <div class="commonNotice">
                            <div class="ulbox">
                                <div v-for="(item, index) in checkItem" :key="index">
                                    <el-checkbox
                                        v-model="item.isRequired"
                                        :true-label="1"
                                        :false-label="0"
                                        :label="item.itemName"
                                    ></el-checkbox>
                                </div>
                            </div>
                        </div>
                    </ex-card>

                    <!--签名-->
                    <ex-card  :headerName="item.signatureName" v-for="(item, index) in form.signatureForms" :key="index">
                        <div class="commonNotice">
                            <div class="ulbox">
                                <el-form-item :prop="'signatureForms['+index+'].signatureAdvice'"
                                              :rules="{
                                            required: true, message: '请输入内容', trigger: 'blur'
                                        }">
                                    <el-input
                                        type="textarea"
                                        v-model="form['signatureForms'][index].signatureAdvice"
                                        :autosize="{minRows: 4}"
                                        placeholder="请输入内容"
                                        style="margin-top: 10px"
                                    ></el-input>
                                </el-form-item>
                                <div>
                                    <div style="display: inline-block"><span style="color: #F56C6C;margin-right: 5px;">*</span>{{ item.signatureTips }}<el-button @click="openSignDialogHandler(index)">点击进入电子签章</el-button></div>
                                    <div class="check-date">检验日期：{{ getNowFormatDate() }}</div>
                                </div>
                                <div>
                                    <img
                                        v-if="item.file"
                                        :src="`${$client.serverUrl}files/${item.file.id}?token=${$store.state.token}`"
                                        alt
                                        class="sign-img"
                                    />

                                </div>
                            </div>
                        </div>
                    </ex-card>
                </div>
            </el-card>

            <!--客户受电工程竣工检验意见书 （背面）-->
            <el-card class="card-pub-notice card-ext-center-title">
                <div slot="header" class="notice_title">
                    <span>{{ $t('客户受电工程竣工检验意见书 （背面）') }}</span>
                </div>
                <div class="commonNotice">
                    <!--整改内容-->
                    <ex-card headerName="整改内容">
                        <div class="commonNotice">
                            <div class="ulbox">
                                <el-form-item>
                                    <el-input
                                        type="textarea"
                                        row="4"
                                        :autosize="{minRows: 4}"
                                        placeholder="请输入内容"
                                        v-model="form.rectifyOpinions"
                                    ></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </ex-card>
                </div>
            </el-card>
        </el-form>

        <sign ref="ref_elec_sign" v-if="showSign" @success="signCallback"></sign>
        <div class="submit-btn-group">
            <el-button @click="cancelHandler()">取消</el-button>
            <el-button type="primary" @click="saveHandler(2)">保存</el-button>
            <el-button type="danger" @click="saveHandler(0)">驳回</el-button>
            <el-button type="primary" @click="saveHandler(1)">通过验收</el-button>
        </div>
    </div>
</template>

<script>
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Sign from '@/components/Sign/index';
import StepBar from '@/components/StepBar/index';
import {TASK_STATUS} from '@/utils/constants';

export default {
    name: 'completionAcceptance',
    components: {
        Sign,
        StepBar,
        [CollapseTransition.name]: CollapseTransition,
    },
    data() {
        return {
            TASK_STATUS,
            taskInfo: {},
            completionApplyFiles: [],
            completionCheckSceneFiles: [],
            showSign: false,
            collapse: true,
            customerSignature: {
                signatureAdvice: '',
                signatureName: '客户签名',
                signatureTips: '客户签名：',
                file: {},
            },
            form: {
                applyId: null,
                constructionItem: [],
                // formState	:0
                elCompletionAcceptanceItemForms: [],
                ossIds: [],
                rectifyOpinions: '',
                signatureForms: [
                    {
                        signatureAdvice: '',
                        signatureName: '供电企业竣工检验意见',
                        signatureTips: '检验人员签名：',
                        file: {},
                    },
                    /*{
                        signatureAdvice: '',
                        signatureName: '客户确认检验意见',
                        signatureTips: '客户代表签名：',
                        file: {},
                    },
                    {
                        signatureAdvice: '',
                        signatureName: '施工单位确认检验意见',
                        signatureTips: '施工单位代表签名：',
                        file: {},
                    }*/
                ],
            },
            businessInfo: {},
            rules: {
                /*constructionItem: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur',
                    },
                ]*/
            },
            checkItem: [
                {isRequired: 1, itemName: '断路器、负荷开关、户外隔离开关'},
                {isRequired: 1, itemName: '跌落式开关'},
                {isRequired: 1, itemName: '高压避雷器'},
                {isRequired: 1, itemName: '相线对地、相间安全距离'},
                {isRequired: 1, itemName: '高压电缆及其安装'},
                {isRequired: 1, itemName: '高压柜安装布置'},
                {isRequired: 1, itemName: '高压母线及其安装'},
                {isRequired: 1, itemName: '继电保护'},
                {isRequired: 1, itemName: '变压器、台架、安装布置'},
                {isRequired: 1, itemName: '低压线路及其安装'},
                {isRequired: 1, itemName: '低压柜（计量箱）安装布置'},
                {isRequired: 1, itemName: '低压柜（箱）母线及其安装'},
                {isRequired: 1, itemName: '进网电工证情况'},
                {isRequired: 1, itemName: '电容补偿装置'},
                {isRequired: 1, itemName: '低压避雷器'},
                {isRequired: 1, itemName: '低压出线开关'},
                {isRequired: 1, itemName: '接地电阻'},
                {isRequired: 1, itemName: '标示牌齐全'},
                {isRequired: 1, itemName: '电房等配电场地土建'},
                {isRequired: 1, itemName: '安全工具'},
                {isRequired: 1, itemName: '消防设施'},
                {isRequired: 1, itemName: '业扩相关文档资料'},
                {isRequired: 1, itemName: '操作规程及制度'},
                {isRequired: 1, itemName: '高压配电一次模拟图'},
                {isRequired: 1, itemName: '低压配电一次模拟图'},
                {isRequired: 1, itemName: '其他'},
            ],
            // 折叠卡片包
            planFold: {
                completionApplyFiles: false, //验收申请文件
                operationTable: false, //现场作业表
                customerInfo: false, //基本信息
                customerCommitment: false, //客户承诺
                standard: false, //竣工检验项目是否符合标准
                signature0: false, //供电企业竣工检验意见
                signature1: false, //客户确认检验意见
                signature2: false, //施工单位确认检验意见
                rectify: false, //整改内容
            },
        };
    },
    methods: {
        async init(row) {
            //初始化数据
            this.taskInfo = row;
            this.form.applyId = this.taskInfo.businessFormId;
            this.form.workflowTaskId = this.taskInfo.id;
            if (this.taskInfo.currentStatus === TASK_STATUS.COMPLETION_ACCEPTANCE_SAVED) {
                await this.getCompletionAcceptance();
            } else {
                this.getCompletionApplyFiles();
            }
            this.getBusiness();

            this.$nextTick(() => {
                //this.$refs.ref_elec_map.init(this.form.applyId);
            });
        },
        getTxt(value) {
            return this.$client.serverUrl + value.url;
        },
        // card 折叠方法
        shrinkComponents(name) {
            this.planFold[name] = !this.planFold[name];
        },
        openSignDialogHandler(index) {
            this.showSign = true;
            this.signIndex = index;
            this.$nextTick(() => {
                this.$refs.ref_elec_sign.init();
            });
        },
        signCallback(data) {
            this.showSign = false;
            if (this.signIndex === 3) {
                this.customerSignature.file = data;
            } else {
                this.$set(this.form.signatureForms[this.signIndex], 'file', data);
            }
        },
        getCompletionApplyFiles() {
            this.$client.getCompletionApply(this.form.applyId).then((result) => {
                if (result.data && result.data.completionFiles && Array.isArray(result.data.completionFiles)) {
                    this.completionApplyFiles = result.data.completionFiles;
                }
            });
        },
        async getCompletionAcceptance() {
            await this.$client.getCompletionAcceptance(this.form.applyId).then((result) => {
                if (result && result.code === 0) {
                    //有数据，需要回显
                    this.form = {...this.form, ...result.data};

                    // 签名
                    this.form.signatureForms = [];
                    this.form.signatureForms.push({
                        ...this.form.acceptanceSignature,
                        signatureName: '供电企业竣工检验意见',
                        signatureTips: '检验人员签名：',
                    });
                    /*this.form.signatureForms.push({
                        ...this.form.agentSignature,
                        signatureName: '客户确认检验意见',
                        signatureTips: '客户代表签名：',
                    });
                    this.form.signatureForms.push({
                        ...this.form.workUnitSignature,
                        signatureName: '施工单位确认检验意见',
                        signatureTips: '施工单位代表签名：',
                    });*/

                    this.customerSignature = {
                        ...this.form.customerSignature,
                        signatureName: '客户签名',
                        signatureTips: '客户签名',
                    };
                    //竣工检验项目是否符合标准
                    if (result.data.completionAcceptanceItems && result.data.completionAcceptanceItems.length > 0) {
                        this.checkItem = result.data.completionAcceptanceItems;
                    }
                    //验收文件
                    if (result.data.completionFiles && result.data.completionFiles.length > 0) {
                        this.completionApplyFiles = result.data.completionFiles;
                    }
                    //现场作业表
                    if (result.data.completionCheckSceneFiles && result.data.completionCheckSceneFiles.length > 0) {
                        this.completionCheckSceneFiles = result.data.completionCheckSceneFiles;
                    }
                }
            });

            const promise = new Promise((resolve) => {
                resolve(); // fulfilled
            });

            return promise;
        },
        async downloadTemplateHandle() {
            const link = document.createElement('a');
            link.href = `${this.$client.serverUrl}template/竣工验收作业表.doc?token=${this.$store.state.token}`;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 0);
        },
        getBusiness() {
            this.$client.getBusinessInfo(this.form.applyId).then((result) => {
                if (result && result.code === 0) {
                    this.businessInfo = result.data;
                }
            });
        },
        saveHandler(formState) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    /* if (!this.completionCheckSceneFiles || this.completionCheckSceneFiles.length === 0) {
                        this.$message({
                            message: '请上传检验现场作业表',
                            type: 'error'
                        });
                        return;
                    }*/
                    /* if (!this.customerSignature.file || !this.customerSignature.file.id) {
                        this.$message({
                            message: '请客户签名',
                            type: 'error'
                        });
                        return;
                    }*/
                    for (let sign of this.form.signatureForms) {
                        if (!sign.file || !sign.file.id) {
                            let msg = sign.signatureTips.replace('：', '');
                            this.$message({
                                message: '请' + msg,
                                type: 'error'
                            });
                            return;
                        }
                    }

                    let title = '';
                    if (formState === 0) {
                        title = '确认驳回？';
                    } else if (formState === 1) {
                        title = '确认通过验收？';
                    } else if (formState === 2) {
                        title = '确认保存？';
                    }

                    this.$confirm(title, {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.saveData(formState);
                    });
                } else {
                    this.$message({
                        message: '请检查输入数据',
                        type: 'error'
                    });
                }
            });
        },
        async saveData(formState) {
            const data = {
                acceptanceSignature: {...this.form.signatureForms[0]},
                agentSignature: {...this.form.signatureForms[1]},
                applyId: this.form.applyId,
                businessNumber: this.businessInfo.businessNumber,
                completionAcceptanceItems: [...this.checkItem],
                completionFiles: [...this.completionApplyFiles],
                completionCheckSceneFiles: [...this.completionCheckSceneFiles],
                constructionItem: this.form.constructionItem,
                customerName: this.businessInfo.customerName,
                customerNumber: this.businessInfo.customerNumber,
                customerSignature: {...this.customerSignature},
                formState,
                id: this.form.id,
                oldCapacity: this.form.oldCapacity,
                //operationOssIds: this.fileList.map(item => item.response.data.id),
                rectifyOpinions: this.form.rectifyOpinions,
                totalCapacity: this.form.totalCapacity,
                updateCapacity: this.form.updateCapacity,
                useAddr: this.businessInfo.useAddr,
                useRelName: this.businessInfo.useRelName,
                useRelTel: this.businessInfo.useRelTel,
                workUnitSignature: {...this.form.signatureForms[2]},
                workflowTaskId: this.form.workflowTaskId,
            };
            this.$refs.form.validate(() => {
                this.$client
                    .addCompletionAcceptance(data)
                    .then((result) => {
                        if (result && result.code === 0) {
                            this.$message.success('操作成功');
                            //关闭表单
                            this.$emit('closeForm', 2);
                        } else {
                            this.$message.error(result.msg || '操作失败');
                        }
                    })
                    .catch(({msg}) => {
                        this.$message.error(msg || '操作失败');
                    });
            });
        },
        cancelHandler() {
            this.$confirm('确认取消保存？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$emit('closeForm', 2);
            });
        },
        getNowFormatDate() {
            const date = new Date();
            const seperator1 = '-';
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            return year + seperator1 + month + seperator1 + strDate;
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        },
    },
    async created() {},
};
</script>

<style lang="less" scoped>
.check-date {
    float: right;
}

.sign-img {
    width: 300px;
    height: auto;
    vertical-align: middle;
}

.uploader {
    margin: 10px 20px 10px 20px;
    display: inline-block;
    text-align: center;
}
</style>
