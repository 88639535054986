<template>
  <el-dialog
    class="sign"
    title="签名"
    :append-to-body="true"
    :visible.sync="visible"
    :close-on-click-modal="false"
    center>
    <div class="sign">
      <canvas id="canvas" :width="width" :height="height" style="background-color:white;"></canvas>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="warning" @click="clear()">清空</el-button>
      <el-button type="primary" @click="save()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
    name: 'Sign',
    props: {
        width: {
            type: String,
            default: '800'
        },
        height: {
            type: String,
            default: '300'
        },
        strokeStyle: {
            type: String,
            default: '#000'
        },
        showUrl: {
            type: Boolean,
            default: true
        },
        imgWidth: {
            type: String,
            default: '240'
        },
        imgHeight: {
            type: String,
            default: '106'
        }
    },
    data() {
        return {
            type: null,
            visible: false,
            isDown: false,
            mousePressed: false,
            canvas: null, // canvas
            ctx: null, // ctx canvas对象
            stroke_info: null, // 当前绘图的坐标
            url: '' // base64 图像
        };
    },
    methods: {
        init(type) {
            this.type = type;
            this.visible = true;

            const that = this;
            this.$nextTick(() => {
                this.canvas = document.getElementById('canvas');
                this.ctx = this.canvas.getContext('2d');
                this.stroke_info = this.canvas.getBoundingClientRect();
                this.canvas.onmousedown = function(event) {
                    that.darwStart(event);
                };

                this.canvas.onmouseup = function() {
                    that.drawEnd();
                };
                this.canvas.onmousemove = function(event) {
                    if (that.mousePressed) {
                        that.darwMove(event, true);
                    }
                };
                this.clear();
            });
        },
        darwStart(e) {
            const t = e;
            this.mousePressed = true;
            this.ctx.strokeStyle = this.strokeStyle;
            this.ctx.beginPath(); // 清空所有绘画路径
            this.ctx.moveTo(t.clientX - this.stroke_info.left, t.clientY - this.stroke_info.top);
            this.darwMove(e, true);
        },
        darwMove(e, isDown) {
            const t = e;
            if (isDown) {
                this.ctx.lineTo(t.clientX - this.stroke_info.left, t.clientY - this.stroke_info.top);
                this.ctx.stroke();
            }
        },
        drawEnd() {
            this.mousePressed = false;
        },
        clear() {
            this.ctx.clearRect(0, 0, this.width, this.height);
            this.url = '';
        },
        save() {
            //let result = this.canvas.toDataURL("image/jpeg");
            const me = this;
            //设置透明区域为白色
            const imageData = this.ctx.getImageData(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < imageData.data.length; i += 4) {
                // 当该像素是透明的，则设置成白色
                if (imageData.data[i + 3] === 0) {
                    imageData.data[i] = 255;
                    imageData.data[i + 1] = 255;
                    imageData.data[i + 2] = 255;
                    imageData.data[i + 3] = 255;
                }
            }
            this.ctx.putImageData(imageData, 0, 0);

            this.canvas.toBlob(function(result) {
                const formData = new FormData();
                formData.append('file', result, '客户签名.jpg');
                me.$client.uploadFile(formData).then((data) => {
                    if (data && data.code === 0) {
                        me.$emit('success', data.data, me.type);
                        me.visible = false;
                    } else {
                        me.$message.error(data.msg);
                    }
                });
            }, 'image/jpeg', 1);
        }
    }
};
</script>

<style>
  .sign .el-dialog{
    width: 850px !important;
  }
</style>
